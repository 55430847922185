import React from 'react';
import ocr from '../../../../../../assets/img/minerva/searchDataProcessingFeature/mobile_minerva_ocr_image.png'
import ai from '../../../../../../assets/img/minerva/searchDataProcessingFeature/mobile_minerva_ai_image.png'
import stt from '../../../../../../assets/img/minerva/searchDataProcessingFeature/mobile_minerva_stt_image.png'
import descOcr from '../../../../../../assets/img/minerva/searchDataProcessingFeature/minerva_ocr_image.png'
import descAi from '../../../../../../assets/img/minerva/searchDataProcessingFeature/minerva_ai_image.png'
import descStt from '../../../../../../assets/img/minerva/searchDataProcessingFeature/minerva_stt_image.png'
import styles from './searchDataProcessingFeature.module.scss'
import {useWindowResizeToMobile} from "../../../../../features/util/responsive/mobile";
import useIntersectionObserver from "../../../../../features/util/commonFacility/sectionsObserver";
import {IsVisibleBoxSectionStyled} from "../../../../../features/styledComponents/common/isVisibleBox";

const SearchDataProcessingFeature = () => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.2});
  const isMobile = useWindowResizeToMobile();

  function mobileBg(index: number) {
    if (index !== 0 && index !== 3 && index !== 5) return
    return styles.search_data_processing_feature
  }

  return (
    <IsVisibleBoxSectionStyled
        className={`flex flex-col items-center text-sm my-16 w-full text-[#243242] relative md:text-base md:pt-28 lg:max-w-screen-xl lg:mx-auto`}
        ref={innerRef} isVisible={isVisible}
    >

      {isMobile &&
        <>
          <h2 className={`text-xl font-bold mt-6 mb-10`}>検索データ処理の特徴</h2>
          <div className={`w-full mt-4 px-6`}>
            {
              dataTable.map((data, index) => {
                return (
                  <div key={index} className={`relative ${mobileBg(index)}`}>
                    <div
                      className={`relative flex flex-col items-center border border-[#FFA7E4] p-4 rounded-lg mb-4 bg-white`}>
                      <h3
                        className={`${(data.desc || data.descList) && 'mb-3'} text-base font-semibold`}>{data?.title}</h3>
                      {data?.desc && <p dangerouslySetInnerHTML={{__html: data?.desc}}/>}
                        {data?.descList &&
                        <ul className={`list-disc pl-6 space-y-1`}>
                          {data?.descList.map((list, listIndex) => (
                            <li key={listIndex}>{list}</li>
                          ))}
                        </ul>
                      }
                      {data?.img &&
                        <img src={data?.img} alt={data.title} className={`mt-3`}/>
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </>}
      {!isMobile &&
        <div className={`w-full h-full relative`}>
          <h2 className={`text-3xl font-bold absolute inset-x-0 -top-8 z-10 text-center`}>検索データ処理の特徴</h2>
          <div className={`${styles.div_mask}`}>
            <article className={`w-full h-14 flex justify-center`}>
              <div className={`w-3/12 border-r-[1.5px] border-[#7E8FA7]`}/>
              <div className={`relative text-xl font-bold w-6/12 min-w-[43.6rem]`}>
                <div className={`text-center text-3xl mb-10`}></div>
              </div>
              <div className={`w-3/12 border-l-[1.5px] border-[#7E8FA7]`}/>
            </article>

            <article className={`w-full h-full flex justify-center`}>
              <div className={`w-3/12 flex justify-start border-t-[1.5px] border-r-[1.5px] border-[#7E8FA7]`}>
                <div className={`w-1/3 border-r-[1.5px] border-[#7E8FA7] hidden lg:block`}/>
              </div>
              <div className={`relative w-6/12 border-t-[1.5px] border-[#7E8FA7] p-6 min-w-[43.6rem]`}>
                <div className={`px-6 py-12 bg-white flex flex-col justify-center shadow-custom-shadow rounded-lg`}>
                  <h3 className={`text-center text-xl font-bold mb-4`}>形態素分析機能</h3>
                  <p
                    className={`text-center`}>例）蘭方医療の実践に用いた薬物の供給·確保などの関連研究は学際的である。<br/><span
                    className="-ml-12">⇒</span> 蘭方｜医療｜実践｜用いる｜薬物｜供給｜確保｜関連｜研究｜学際
                  </p>
                </div>
              </div>
              <div className={`w-3/12 flex justify-start border-t-[1.5px] border-l-[1.5px] border-[#7E8FA7]`}>
                <div className={`w-2/3 border-r-[1.5px] border-[#7E8FA7] hidden lg:block`}/>
              </div>
            </article>

            <article className={`w-full h-full flex justify-center`}>
              <div className={`w-3/12 flex justify-start border-t-[1.5px] border-r-[1.5px] border-[#7E8FA7]`}>
                <div className={`w-1/3 border-r-[1.5px] border-[#7E8FA7] hidden lg:block`}/>
              </div>
              <div
                className={`relative w-6/12 border-t-[1.5px] border-[#7E8FA7] px-6 py-6 flex justify-between min-w-[43.6rem] min-h-[13rem]`}>
                <div className={`p-6 w-[38%] bg-white flex flex-col justify-center shadow-custom-shadow rounded-lg`}>
                  <h3 className={`text-center text-xl font-bold`}>専門用語登録</h3>
                </div>
                <div className={`p-6 w-[58%] bg-white flex flex-col justify-center shadow-custom-shadow rounded-lg`}>
                  <h3 className={`text-center text-xl font-bold`}>略称など使用者定義辞書登録</h3>
                </div>
              </div>
              <div className={`w-3/12 flex justify-start border-t-[1.5px] border-l-[1.5px] border-[#7E8FA7]`}>
                <div className={`w-2/3 border-r-[1.5px] border-[#7E8FA7] hidden lg:block`}/>
              </div>
            </article>

            <article className={`w-full h-full flex justify-center`}>
              <div className={`w-3/12 flex justify-start border-y-[1.5px] border-r-[1.5px] border-[#7E8FA7]`}>
                <div className={`w-1/3 border-r-[1.5px] border-[#7E8FA7] hidden lg:block`}/>
              </div>
              <div className={`relative w-6/12 border-y-[1.5px] border-[#7E8FA7] p-6 min-w-[43.6rem]`}>
                <div
                  className={`px-6 py-12 bg-white flex flex-col justify-center shadow-custom-shadow items-center rounded-lg`}>
                  <h3 className={`text-center text-xl font-bold mb-4`}>単語正規化処理</h3>
                  <ul className={`list-disc pl-6 w-3/4`}>
                    <li>
                      送り違い : 例）打込む ⇒ 打ち込む
                    </li>
                    <li>
                      字種 : 例）かつ丼 ⇒ カツ丼
                    </li>
                    <li>
                      異体字 : 例）附属 ⇒ 付属
                    </li>
                    <li>
                      誤用 : 例）シュミレーション ⇒ シミュレーション
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`w-3/12 flex justify-start border-y-[1.5px] border-l-[1.5px] border-[#7E8FA7]`}>
                <div className={`w-2/3 border-r-[1.5px] border-[#7E8FA7] hidden lg:block`}/>
              </div>
            </article>

            <article className={`w-full h-full flex justify-center`}>
              <div className={`w-3/12 flex justify-start border-[#7E8FA7] border-r-[1.5px] `}/>
              <div className={`relative w-6/12 border-[#7E8FA7] p-10 min-w-[43.6rem]`}>

              </div>
              <div className={`w-3/12 flex justify-start border-[#7E8FA7] border-l-[1.5px]`}/>
            </article>
          </div>

          <article className={`flex justify-center items-center w-full -ml-1 -mb-20`}>
            <div className={`w-1/3`}>
              <div className={`translate-x-1/4`}>
                <h3 className={`text-xl font-bold mb-5 w-[17.8125rem]`}>画像OCR機能</h3>
                <p className={`text-[#0012E6] text-base min-w-[20rem] w-[100%]`}>ファイル内の画像から文字情報を抽出した後にインデックス処理して検索が可能です。
                </p>
              </div>
            </div>
            <div className={`w-2/3 rounded-[25rem] -z-10`}>
              <img src={descOcr} alt="画像OCR機能"/>
            </div>
          </article>
          <article className={`flex justify-center items-center flex-row-reverse w-full -ml-9 -mb-20`}>
            <div className={`w-1/3`}>
              <div className={`-translate-x-1/4`}>
                <h3 className={`text-xl font-bold mb-5 w-[17.8125rem]`}>音声ファイルSTT機能</h3>
                <p
                  className={`text-[#7000B5] text-base min-w-[20rem] w-[100%]`}>STT（Speech-To-Text）は音声をテキストに変換する機能で、<br/>会議の内容やお客様との通話内容を検索することが可能です。
                </p>
              </div>
            </div>
            <div className={`w-2/3 rounded-[25rem] -z-10`}>
              <img src={descStt} alt="音声ファイルSTT機能"/>
            </div>
          </article>
          <article className={`flex justify-center items-center w-full -ml-1 -mb-64`}>
            <div className={`w-1/3`}>
              <div className={`translate-x-1/4`}>
                <h3 className={`text-xl font-bold mb-5 w-[17.8125rem]`}>AIのおすすめ検索・文書内AI検索</h3>
                <p className={`text-[#008868] text-base min-w-[20rem] w-[100%]`}>検索語と似ている検索語をAIモデルからおすすめキーワードを提供します。<br/>
                  文書の内容に基づいて使用者が探したいことをAIに質問して答えを得られます。
                </p>
              </div>
            </div>
            <div className={`w-2/3 rounded-[25rem] -z-10`}>
              <img src={descAi} alt="AIのおすすめ検索・文書内AI検索"/>
            </div>
          </article>
        </div>}

    </IsVisibleBoxSectionStyled>
  );
};

export default SearchDataProcessingFeature;

const dataTable = [
  {
    title: '形態素分析機能',
    desc: '例）蘭方医療の実践に用いた薬物の供給·確保などの関連研究は学際的である。<br/><span className="ml-2">⇒</span>　蘭方｜医療｜実践｜用いる｜薬物｜供給｜確保｜関連｜研究｜学際',
  },
  {
    title: '専門用語登録',
  },
  {
    title: '略称など使用者定義辞書登録',
  },
  {
    title: '単語正規化処理',
    descList: [
      '送り違い : 例）打込む ⇒　打ち込む',
      '字種 : 例）かつ丼 ⇒　カツ丼',
      '異体字 : 例）附属 ⇒　付属',
      '誤用 : 例）シュミレーション ⇒　シミュレーション',
    ]
  },
  {
    title: '画像OCR機能',
    desc: 'ファイル内の画像から文字情報を抽出した後にインデックス処理して検索が可能です。',
    img: ocr,
  },
  {
    title: '音声ファイルSTT機能',
    desc: 'STT（Speech-To-Text）は音声をテキストに変換する機能で、会議の内容やお客様との通話内容を検索することが可能です。',
    img: stt,
  },
  {
    title: 'AIのおすすめ検索・文書内AI検索',
    desc: '検索語と似ている検索語をAIモデルからおすすめキーワードを提供します。 文書の内容に基づいて使用者が探したいことをAIに質問して答えを得られます。',
    img: ai,
  }
]
