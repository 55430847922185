import React from 'react';
import Map from "./map";
import {mapsInfo} from "../../../../../assets/data/mapsInfo";
import osaka1 from "../../../../../assets/img/access/osaka-1.jpg"
import osaka2 from "../../../../../assets/img/access/osaka-2.jpg"
import osakaIcon from "../../../../../assets/img/access/icon/osakaIcon.svg"
import seoul1 from "../../../../../assets/img/access/seoul-1.jpg"
import seoul2 from "../../../../../assets/img/access/seoul-2.jpg"
import seoulIcon from "../../../../../assets/img/access/icon/seoulIcon.svg"
import suidobashi1 from "../../../../../assets/img/access/suidobashi-1.jpg"
import suidobashi2 from "../../../../../assets/img/access/suidobashi-2.jpg"
import suidobashiIcon from "../../../../../assets/img/access/icon/suidobashiIcon.svg"
import mobileOsaka1 from "../../../../../assets/img/access/mobile_osaka-1.jpg"
import mobileOsaka2 from "../../../../../assets/img/access/mobile_osaka-2.jpg"
import mobileSeoul1 from "../../../../../assets/img/access/mobile_seoul-1.jpg"
import mobileSeoul2 from "../../../../../assets/img/access/mobile_seoul-2.jpg"
import mobileSuidobashi1 from "../../../../../assets/img/access/mobile_suidobashi-1.jpg"
import mobileSuidobashi2 from "../../../../../assets/img/access/mobile_suidobashi-2.jpg"
import useIntersectionObserver from "../../../../features/util/commonFacility/sectionsObserver";
import {useWindowResizeToMobile} from "../../../../features/util/responsive/mobile";
import {useWindowResizeToTablet} from "../../../../features/util/responsive/tablet";

export type ContentItem = {
  data1: string;
  data2: string | null | undefined;
};
const Maps = () => {
  const {ref: innerRef, isVisible} = useIntersectionObserver({threshold: 0.1});
  const images = {
    suidobashi: [suidobashi1, suidobashi2], osaka: [osaka1, osaka2], seoul: [seoul1, seoul2],
  };
  const mobileImages = {
    suidobashi: [mobileSuidobashi1, mobileSuidobashi2], osaka: [mobileOsaka1, mobileOsaka2], seoul: [mobileSeoul1, mobileSeoul2],
  };
  const icon = {
    suidobashi: suidobashiIcon, osaka: osakaIcon, seoul: seoulIcon
  }
  const color = {
    suidobashi: "#5EB4E7", osaka: "#03BF6F", seoul: "#F8C44F"
  }
  const location = {
    suidobashi: 'TOKYO', osaka: 'OSAKA', seoul: 'SEOUL'
  }
  const keys = ["suidobashi", "osaka", "seoul"];
  const isMobile = useWindowResizeToMobile();
  const isTablet = useWindowResizeToTablet();

  return (

    <div className="mb-16 ml-6 mr-6 overflow-hidden
     lg:mt-16 lg:mb-16">
      {
        mapsInfo.map((data, mapIndex: number) => {
          const selectedKey = keys[mapIndex];
          let selectedImages;
          let selectedIcon;
          let selectedColor;
          let selectedLocation: string | null | undefined;
          if (selectedKey === 'suidobashi' || selectedKey === 'osaka' || selectedKey === 'seoul') {
            selectedImages = isMobile? mobileImages[selectedKey] : images[selectedKey];
            selectedIcon = icon[selectedKey];
            selectedColor = color[selectedKey];
            selectedLocation = location[selectedKey];
          }
          const contents: ContentItem[] = isTablet||isMobile ? [
            {"data1": "住所", "data2": data.mobileAddress},
            {"data1": "電話番号", "data2": data.phone},
            {"data1": "E-mail", "data2": data.email},
            {"data1": "営業時間", "data2": data.businessHours},
          ] : [
            {"data1": "住所", "data2": data.address},
            {"data1": "電話番号 & E-mail", "data2": data.phone+"<br>"+data.email},
            {"data1": "営業時間", "data2": data.businessHours},
          ]
          return (
            // 1個のオフィス
            <div key={`accessKeys-${mapIndex}`}
              className={`flex flex-col mt-12 mb-24 items-center
              ${(mapIndex+2)%2 === 0?"lg:flex-row":"lg:flex-row-reverse"}
              lg:justify-center lg:gap-8 xl:gap-16 lg:mt-24 lg:mb-32 lg:h-[23rem]`}>
              <div className="w-full max-w-md lg:max-w-lg lg:min-w-[31.1rem]">
                {/*オフィスタイトル*/}
                <div className="flex mb-4 gap-2">
                  <picture className="content-center">
                    <img className="w-7 h-7 lg:w-9 lg:h-9" src={selectedIcon} alt={''}/>
                  </picture>
                  <div className="flex relative items-end">
                    <p className="absolute text-4xl opacity-5 font-bold -bottom-1
                    lg:text-5xl lg:-bottom-0">{selectedLocation}</p>
                    <p className="font-bold leading-3 lg:text-xl">{data.area}</p>
                  </div>
                </div>
                {/*オフィスの説明&建物イメージ*/}
                <div className="flex flex-col lg:flex-col-reverse">
                  <div className="flex flex-col lg:mt-8 lg:flex-row">
                    {/*オフィスの説明*/}
                    {
                      contents.map((content: ContentItem,index) => {
                        return (
                          <div key={index} className={`flex gap-6 
                           lg:flex-col lg:w-full lg:min-w-[11rem] lg:gap-4
                           ${contents.length === index+1? "":"lg:border-r-2"}`}>
                            <p className="w-1/4 content-center border-r-2 font-semibold
                             lg:w-full lg:border-r-0 lg:text-4 lg:pl-3">{content.data1}</p>
                            <p className="content-center w-3/4 font-normal text-xs
                            lg:w-full lg:pl-3" dangerouslySetInnerHTML={{__html: content.data2 || ''}}></p>
                          </div>
                        )
                      })
                    }
                  </div>
                  {/*建物イメージ*/}
                  <div className="flex gap-x-4 max-w-md lg:max-w-lg">
                    {
                      selectedImages?.map((imgSrc, imageIndex) => {
                        return (
                          <div key={`mapKeys-${imageIndex}`}>
                            <div className="pt-4">
                              {
                                imageIndex === 0 ?
                                  <img src={imgSrc} alt={`${selectedLocation}-A` ?? ''}/> :
                                  <img src={imgSrc} alt={`${selectedLocation}-B` ?? ''}/>
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              {/*オフィスの地図*/}
              {/*iframeの場合、tailwindが効かないのためstyledを使う*/}
              <div className="flex items-center w-full mt-6 max-w-md
               lg:max-w-lg lg:mt-0">
                <div className={`content-center border-[3px] rounded-2xl p-1 h-[19rem]
                  lg:h-[22rem] w-full`} style={{borderColor:selectedColor}}>
                  <Map data={data}/>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  );
};

export default Maps;
