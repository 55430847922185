import React, {useState} from 'react';
import {
  Box, Button,
  Checkbox,
  Container, FormControlLabel, Grid,
  MenuItem,
  TextField
} from "@mui/material";
import {useForm, SubmitHandler, Controller} from "react-hook-form"
import MuiTheme from "../common/muiTheme";
import {PostRequest} from "../../features/util/data/postRequest";
import {formatPhoneNumber} from "../../features/util/commonFacility/formatPhoneNumber";
import {theme} from "../../features/util/meterialThemeSettings/MeterialThemeSetting";
import {validateCategory} from "../../features/util/validator/validator";
import {PersonalInformationContent} from "../../../assets/data/personalInformationContents";
import {useWindowResizeToTablet} from "../../features/util/responsive/tablet";
import {FormContactMold} from "../../features/styledComponents/common/formBox";
import {DecoImage} from "../../features/decoration/decoration";
import {ClickEventButton} from "../../features/styledComponents/common/Button";
import {DecoImages} from "../../features/decoration/decoImages";
import MuiModal from "../common/muiModal";
import {useWindowResizeToMobile} from "../../features/util/responsive/mobile";

export interface ContactFormInput {
  category: string;
  company: string;
  name: string;
  email: string;
  phone: string;
  details: string;
  personalCheck?: boolean;
}

const CommunicationContact = () => {
  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    setValue,
    reset,
    trigger,
    control,
  } = useForm<ContactFormInput>({
    mode: 'onChange',
    defaultValues: {
      category: '',
      personalCheck: false,
    },
  })
  const isTablet = useWindowResizeToTablet();
  const isMobile = useWindowResizeToMobile();
  const [msgTitle, setMsgTitle] = useState<string>('　');
  const [msgContent, setMsgContent] = useState<string>('　');
  const [open, setOpen] = useState<boolean>(false);

  const onBlurCategory = async () => {
    await trigger("category"); // 'category' 필드에 대한 유효성 검사를 트리거합니다.
  };
  const onBlurCompany = async () => {
    await trigger("company");
  }
  const onBlurName = async () => {
    await trigger("name");
  }
  const onBlurEmail = async () => {
    await trigger("email");
  }
  const onBlurPhone = async () => {
    await trigger("phone");
  }
  const onBlurDetails = async () => {
    await trigger("details");
  }

  const onSubmit: SubmitHandler<ContactFormInput> = (data: ContactFormInput) => {
    const {personalCheck, ...restOfData} = data;
    if (personalCheck) {
      PostRequest.postContact(restOfData)
        .then(res => {
          reset({
            category: '',
            company: '',
            name: '',
            email: '',
            phone: '',
            details: '',
            personalCheck: false,
          });
          setOpen(true);
          setMsgTitle('転送完了');
          setMsgContent('メールが送信されました。<br/>お問い合わせありがとうございます。');
        })
        .catch(error => {
          setOpen(true);
          setMsgTitle('転送失敗');
          setMsgContent('メールの送信に失敗しました。<br/>しばらくしてからもう一度お試しください。');
        })
    }
  }

  function resetData() {
    reset({
      category: '',
      company: '',
      name: '',
      email: '',
      phone: '',
      details: '',
      personalCheck: false,
    });
  }

  const handlePhoneChange = (event: any) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setValue('phone', formattedPhoneNumber);
  };
  const gridSize = (!isTablet && !isMobile) ? 2 : 0;
  const gridItemSize = (!isTablet && !isMobile) ? 6 : 12;

  function closeModal() {
    setOpen(false)
  }

  return (
    <div className={`bg-[#DFEAF4] overflow-hidden`}>
      <MuiModal open={open} onClose={closeModal} title={msgTitle}>
        <div className="bg-[#DFEAF4]">
          <div className={`flex w-full h-48 bg-[#DFEAF4] justify-center items-center`}>
            <p dangerouslySetInnerHTML={{
              __html: msgContent
            }} className={`text-center px-4 leading-relaxed`}/>
          </div>
          <div className={`flex justify-center bg-[#DFEAF4]`}>
            <ClickEventButton onClick={closeModal}
                    className={`py-[0.7rem] px-0 w-2/5 rounded-[0.6rem] text-white 
                    -mt-4 h-12 shadow-none ${msgTitle === '転送完了' ? 'bg-[#1876D2]' : 'bg-[#FF577F]'} `}>閉じる</ClickEventButton>
          </div>
        </div>
      </MuiModal>
      <form onSubmit={handleSubmit(onSubmit)}
            className={`flex justify-center bg-[#F5F7F8] relative overflow-hidden ${isMobile ? 'pt-8 pb-28' : 'py-48'}`}>
        <fieldset>
          {!isMobile &&
            <>
              <DecoImage style={{...DecoImages.paperAirplane,}}/>
              <DecoImage style={{...DecoImages.flyPaperAirPlane,}}/>
              <DecoImage style={{...DecoImages.boxPattern1,}}/>
              <DecoImage style={{...DecoImages.boxPattern2,}}/>
              <DecoImage style={{...DecoImages.boxPattern3,}}/>
              <DecoImage style={{...DecoImages.boxPattern4,}}/>
              <DecoImage style={{...DecoImages.boxPatternPink,}}/>
              <DecoImage style={{...DecoImages.boxPatternBlue,}}/>
            </>}
          <MuiTheme theme={theme}>
            <Container maxWidth={'md'}>
              <div
                className={`border border-black border-b-0 h-[2.5rem] bg-[#1876D2] flex items-center pl-4 rounded-tl-[1rem] rounded-tr-[1rem] rounded-br-none rounded-bl-none`}>
                <div className={`w-4 h-4 rounded-full m-2 bg-[#FF577F]`}/>
                <div className={`w-4 h-4 rounded-full m-2 bg-[#F8D637]`}/>
                <div className={`w-4 h-4 rounded-full m-2 bg-[#2EDB48]`}/>
              </div>
              <FormContactMold p={isMobile ? 2 : 4}>
                <Controller
                  name="category"
                  control={control}
                  rules={{
                    required: "カテゴリーを選んで下さい。",
                    validate: validateCategory,
                  }}
                  render={({field}) => (
                    <TextField
                      select fullWidth size="small" label="カテゴリー" required margin="normal"
                      {...field}
                      error={Boolean(errors.category)}
                      variant="outlined"
                      onBlur={onBlurCategory}
                      helperText={errors.category?.message || '　'}
                      FormHelperTextProps={{
                        style: {opacity: errors.category?.message ? 1 : 0}
                      }}
                      className={'HeaderNotComeDown'}>
                      <MenuItem value={`ハードウェア購入`} className={'HeaderNotComeDown'}>
                        ハードウェア購入
                      </MenuItem>
                      <MenuItem value={'IT機器の保守サービス'} className={'HeaderNotComeDown'}>
                        IT機器の保守サービス
                      </MenuItem>
                      <MenuItem value={'インフラ構築'} className={'HeaderNotComeDown'}>
                        インフラ構築
                      </MenuItem>
                      <MenuItem value={'エンジニア人材派遣'} className={'HeaderNotComeDown'}>
                        エンジニア人材派遣
                      </MenuItem>

                    </TextField>
                  )}
                />
                <TextField margin="normal" fullWidth label="御社名" variant="outlined" required
                           className={'HeaderNotComeDown'} placeholder="例）NamuTechJapan"
                           error={Boolean(errors.company)}
                           helperText={errors.company?.message || '　'}
                           FormHelperTextProps={{
                             style: {opacity: errors.company?.message ? 1 : 0}
                           }}
                           {...register("company", {
                             required: "御社名は必須項目です。"
                           })}
                           onBlur={onBlurCompany}
                           size="small"
                />
                <TextField margin="normal" fullWidth label="お名前" variant="outlined"
                           className={'HeaderNotComeDown'}
                           size="small" required placeholder="例）山田　太郎"
                           error={Boolean(errors.name)}
                           helperText={errors.name?.message || '　'}
                           FormHelperTextProps={{
                             style: {opacity: errors.name?.message ? 1 : 0}
                           }}
                           {...register("name", {
                             required: "お名前は必須項目です。",
                             minLength: {
                               value: 2,
                               message: "2文字以上入力してください。",
                             }
                           })}
                           onBlur={onBlurName}
                           InputLabelProps={{
                             style: {
                               textAlign: 'center',
                               display: 'flex',
                               justifyContent: 'center'
                             },
                           }}
                           InputProps={{
                             style: {
                               verticalAlign: 'middle', // 수직 정렬
                             },
                           }}
                />
                <Grid container spacing={gridSize}>
                  <Grid item xs={gridItemSize}>
                    <TextField margin="normal" fullWidth label="メールアドレス" required
                               className={'HeaderNotComeDown'}
                               placeholder="例）example@email.com" error={Boolean(errors.email)}
                               helperText={errors.email?.message || '　'}
                               FormHelperTextProps={{
                                 style: {opacity: errors.email?.message ? 1 : 0}
                               }}
                               variant="outlined" size="small"
                               {...register("email", {
                                 required: "メールアドレスは必須項目です。",
                                 pattern: {
                                   value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                   message: "正しいメールアドレスではありません。",
                                 },
                               })}
                               onBlur={onBlurEmail}
                    />
                  </Grid>
                  <Grid item xs={gridItemSize}>
                    <TextField margin="normal" fullWidth label="お電話番号" variant="outlined"
                               className={'HeaderNotComeDown'}
                               placeholder="例）080-1111-1111" required type={"tel"}
                               size="small" error={Boolean(errors.phone)}
                               helperText={errors.phone?.message || '　'}
                               FormHelperTextProps={{
                                 style: {opacity: errors.phone?.message ? 1 : 0}
                               }}
                               {...register("phone", {
                                 required: "お電話番号は必須項目です。",
                               })}
                               onBlur={onBlurPhone}
                               inputProps={{maxLength: 13}}
                    />
                  </Grid>
                </Grid>
                <TextField
                  label="お問い合わせ内容" placeholder="出来るだけ詳細にご記入下さい。"
                  className={'HeaderNotComeDown'}
                  multiline required size="small" margin="normal"
                  error={Boolean(errors.details)}
                  helperText={errors.details?.message || '　'}
                  FormHelperTextProps={{
                    style: {opacity: errors.details?.message ? 1 : 0}
                  }}
                  rows={4} fullWidth
                  {...register("details", {
                    required: "お問い合わせ内容は必須項目です。"
                  })}
                  onBlur={onBlurDetails}
                  InputProps={{
                    style: {
                      color: '#A6A6A6',
                      backgroundColor: 'white',
                      borderRadius:'0.6rem'
                    },
                  }}
                />
                <div className={`mt-[5.5rem]`}>
                  <div className={`border-[1.5px] border-black mb-4 p-[0.3rem] rounded-[0.6rem] bg-white`}>
                    <div
                      className={`w-full text-sm h-[15rem] overflow-y-auto rounded-[0.6rem] mt-2 bg-white text-black`}>
                      <p className={`text-center pt-4 px-2 pb-2 font-medium text-sm`}>個人情報の取り扱い</p>
                      <ol className={`m-2 ml-5 p-0 md:p-2 md:pt-0 md:px-3 md:pb-6 text-sm list-decimal`}>
                        {PersonalInformationContent.map((data, index: number) => (
                          <li key={`privacyKey-${index}`}
                              className={`whitespace-pre-wrap leading-[1.1rem] mb-4 pl-1`}
                              dangerouslySetInnerHTML={{__html: data.contents}}/>
                        ))}
                      </ol>
                    </div>
                  </div>
                  <div className={`ml-1`}>
                    <Controller
                      name="personalCheck"
                      control={control}
                      rules={{required: '개인정보'}}
                      render={({field, fieldState: {error}}: any) => (
                        <FormControlLabel
                          className={'HeaderNotComeDown'}
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                            />
                          }
                          label={
                            <span
                              className={`text-sm ${(error || (field.value === false)) ? 'text-[#FF003DFF]' : 'text-black'} leading-7`}>
                              個人情報の取り扱いを確認後、同意します。
                            </span>
                          }
                          labelPlacement="end"
                        />
                      )}
                    />
                  </div>
                  <div className={`flex justify-end mt-12`}>
                    <ClickEventButton size="small" variant="contained" type="reset" style={{backgroundColor: '#FF577F'}}
                                      className={'HeaderNotComeDown border-[1.5px] text-sm font-normal'}
                                      onClick={() => resetData()}
                                      sx={{marginRight: `0.5rem`,}}> 取消 </ClickEventButton>
                    <ClickEventButton disabled={!isValid} size="small"
                                      className={'HeaderNotComeDown border-[1.5px] text-sm font-normal'}
                                      sx={{marginLeft: `0.5rem`,}}
                                      style={{
                                        backgroundColor: isValid ? '#1876D2' : 'rgba(0, 0, 0, 0.12)',
                                        color: 'white'
                                      }}
                                      type="submit">送信</ClickEventButton>
                  </div>
                </div>
              </FormContactMold>
            </Container>
          </MuiTheme>
        </fieldset>
      </form>
    </div>
  );
};
export default CommunicationContact;
